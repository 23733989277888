const schools = [
    {
        date : "2021",
        titre : "Box Sign,The Netherlands",
        description : "Software Developer"
    },
    {
        date : "2021",
        titre : "Sign Request B.V.(Acquired by BOX Inc), The Netherlands",
        description : "Software Engineer"
    },
    {
        date : "2017",
        titre : "Cognizant Technology Solutions, India, The Netherlands",
        description : "Python Developer/Software Engineer"
    },
    {
        date : "2015",
        titre : "Accenture, India",
        description : "Software Engineer Analyst"
    },
    {
        date : "2012",
        titre : "Tech Mahindra Ltd., India, Australia",
        description : "Software Engineer"
    },
    {
        date : "2011",
        titre : "Bachelor of Electronics & Telecommunications",
        description : "Pune University, India"
    }

]

export default schools;