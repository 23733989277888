import projet1 from "../images/projet1.png";
import projet2 from "../images/projet2.jpg";
// import projet3 from '../images/projet3.png'
// import projet4 from '../images/projet4.png'

const projects = [
  {
    title: "Mini Project",
    description:
      "Coming Soon !!!",
    image: projet1,
    langage: "Python",
    date: "Feb 2023",
  },
  {
    title: "Django REST Framework Workshop-Youtube",
    description: "Presented DRF workshop at PyLadies meetup, Amsterdam",
    image: projet2,
    langage: "Python - Django - Bootstrap",
    date: "JANUARY 2023",
  },
  // {
  //   title: "PROJET DE GROUPE",
  //   description:
  //     "Participation à la création d’un jeu 3D de type survie intitulé «Xtermination» en tant que développeur de l’interface utilisateur.",
  //   image: projet3,
  //   langage: "C#",
  //   date: "JANVIER 2019 – AOUT 2019",
  // },
  // {
  //   title: "MINI-PROJET INDIVIDUEL",
  //   description:
  //     "Développement d’un site web de vente sécurisé par le cryptage des données.",
  //   image: projet4,
  //   langage: "HTML - CSS - JavaScript - Bootstrap - PHP - SQL",
  //   date: "MARS 2019 – JUIN 2019",
  // }
];

export default projects;
