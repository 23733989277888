import React from 'react'

function Footer() {
    return(
        <footer className="pt-2 pb-1 text-white text-center">
            <p>© Copyright 2023 - Swaranjali Ingale</p>
        </footer>
    )
}

export default Footer;