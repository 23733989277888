import React from 'react'
import { FaGithub, FaLinkedin, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import '../App.css'

const Contact = () => {
    return (
        <section id="contact" className="mb-5">
            <h1 className="text-center mt-5 text-white">
                Contact
            </h1>
            <div className="underline mx-auto mb-5"></div>
            <div className="row g-0 text-center text-white" data-aos="fade-up">
                <div className="col-lg-3 mb-3" >
                    <a href="https://github.com/swaranjali-sr" target="_blank" rel="noreferrer"><FaGithub size={32} /> <br />Swaranjali Ingale</a>
                </div>
                 <div className="col-lg-3 mb-3">
                    <a href="https://www.linkedin.com/in/swaranjali-ingale-10018640/" target="_blank" rel="noreferrer"><FaLinkedin size={32} /> <br/>swaranjali.ingale@gmail.com</a>
                </div>
                 <div className="col-lg-3 mb-3">
                    <FaPhone size={32} /> <br/> +31 634 52 53 54
                </div>
                 <div className="col-lg-3 mb-3">
                    <FaMapMarkerAlt size={32} /> <br/> Almere, The Netherlands
                </div>
            </div>
        </section>
    )
}

export default Contact
