import React from "react";
import Swa from "../images/swa.jpg";
// import CV from "../data/CV_Swa.pdf";

const About = () => {
  return (
    <section id="about">
      <h1 className="text-center mt-5 text-white">My Bio</h1>
      <div className="underline mx-auto"></div>
      <div className="container" data-aos="fade-right">
        <div class="card mt-5 bg-dark text-white">
          <div class="row g-0">
            <div class="col-lg-3">
              <img src={Swa} class="img-fluid rounded-start" alt="..." />
            </div>
            <div class="col-lg-9">
              <div class="card-body text-center">
                <h1 class="card-title" style={{ color: "#7b4e01" }}>
                  Swaranjali Ingale
                </h1>
                <p
                  class="card-text lh-lg ps-4 pe-4"
                  style={{ textAlign: "justify" }}
                >
                  Hello, I am a Python developer. I specialize in using the
                  Python programming language to build a variety of
                  applications, such as web applications, scientific
                  applications, data analysis and visualization, machine
                  learning models, and more. I have a good understanding of the
                  core concepts of Python, such as object-oriented programming,
                  data structures, algorithms, and scripting. I also keep up to
                  date with the latest developments in the Python community, and
                  am familiar with popular Python libraries and frameworks such
                  as Django, Flask, Pandas, and TensorFlow.
                </p>
                {/* <a href={CV} class="btn btn-outline-info" download>
                  My CV
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
