const skills = [
    {
        langage: "Python",
        longueur : 90    
    },
    {
        langage: "Django Framework",
        longueur : 60    
    },
    {
        langage: "Django REST Framework",
        longueur : 70    
    },
    {
        langage: "Typescript",
        longueur : 40    
    },
    {
        langage: "Javascript",
        longueur : 40    
    },
    {
        langage: "SQL",
        longueur : 80    
    },
    {
        langage: "GCP/AWS",
        longueur : 50    
    }
    // {
    //     langage: "C",
    //     longueur : 45    
    // },
    // {
    //     langage: "PYTHON",
    //     longueur : 50    
    // }
]

export default skills